import { useState } from "react";
import axios from "axios";
import { Card, Input, Typography } from "@material-tailwind/react";

function ForgetPassword() {
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const handleReset = async () => {
    const email = document.getElementById("forgotEmail").value;

    if (email !== "") {
      setError(false);
      try {
        const res = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "api/auth/forgot-password",
          {
            email,
          }
        );

        setSent(true);
      } catch (err) {
        setSent(false);
        setError(true);
        throw new Error(err);
      }
    }
  };

  return (
    <div className="w-full h-full min-w-screen min-h-screen relative">
      <div className=" absolute left-0 top-0 w-full h-full flex items-center justify-center z-[50] pt-[64px]">
        <div className="w-full md:min-w-[600px] lg:min-w-[600px] h-full flex flex-col my-auto bg-transparent items-center justify-center p-4 md:p-14">
          <Card
            color="transparent"
            shadow={false}
            className="border border-primary p-4 md:p-8 rounded-lg"
          >
            <Typography variant="h3" color="white" className="font-bold">
              Forgot Password
            </Typography>
            <Typography color="white" className="mt-1 font-normal">
              We'll send you an email with a link to reset your password
            </Typography>
            <div role="form" className="mt-8 mb-2 w-full max-w-screen-lg">
              <div className="mb-4 flex flex-col gap-6">
                <Input
                  id="forgotEmail"
                  size="lg"
                  label="Email"
                  color="cyan"
                  className="text-secondary-content w-full"
                  error={error}
                />
              </div>
              {error && (
                <div className="text-secondary-content">Invalid Email</div>
              )}
              {sent && (
                <div className="text-secondary-content">
                  Email Successfully sent
                </div>
              )}
              <button
                className="btn btn-primary w-full mt-4"
                onClick={handleReset}
              >
                Send Email
              </button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
