import { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { Select, Option } from "@material-tailwind/react";
import { BsSliders } from "react-icons/bs";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import ListProduct from "../components/ListProduct";
import Loading from "../components/Loading";
import useFetch from "../hooks/useFetch";
import { useRegionChecker } from "../hooks/regionChecker";
import { Helmet } from "react-helmet";

function Products() {
  const { category, subcategory } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = new URLSearchParams(location.search);
  const querySearch = queryParams.get("search");
  const queryIsNew = queryParams.get("isNew");
  const queryIsFeatured = queryParams.get("isFeatured");
  const queryIsPromotion = queryParams.get("isPromotion");
  const queryIsPreorder = queryParams.get("isPreorder");
  const querySortBy = queryParams.get("sortBy");
  const queryIsInStock = queryParams.get("isInStock");
  const queryMinPrice = queryParams.get("minPrice");
  const queryMaxPrice = queryParams.get("maxPrice");
  const queryPage = queryParams.get("page");

  const { region } = useRegionChecker();

  const [isSidebar, setIsSidebar] = useState(false);
  const [isNew, setIsNew] = useState(queryIsNew === "true" ? true : false);
  const [isPromotion, setIsPromotion] = useState(
    queryIsPromotion === "true" ? true : false
  );
  const [isFeatured, setIsFeatured] = useState(
    queryIsFeatured === "true" ? true : false
  );
  const [isPreorder, setIsPreorder] = useState(
    queryIsPreorder === "true" ? true : false
  );
  const [isInStock, setIsInStock] = useState(
    queryIsInStock === "true" ? true : false
  );
  const [price, setPrice] = useState([
    queryMinPrice !== null ? queryMinPrice : 0,
    queryMaxPrice !== null ? queryMaxPrice : 3000,
  ]);
  const [url, setUrl] = useState("");
  const [page, setPage] = useState(queryPage !== null ? Number(queryPage) : 1);
  const [products, setProducts] = useState(null);
  const [sortBy, setSortBy] = useState(
    querySortBy !== null ? querySortBy : null
  );
  const [loading2, setLoading2] = useState(true);
  const [sliderValue, setSliderValue] = useState([
    queryMinPrice !== null ? queryMinPrice : 0,
    queryMaxPrice !== null ? queryMaxPrice : 3000,
  ]);

  const { data: categoryDB } = useFetch(
    `api/categories/?filters[title][$contains]=${category}`
  );

  const { data: subcategoryDB } = useFetch(
    `api/subcategories/?filters[title][$eq]=${subcategory}`
  );

  const { data: brandDB } = useFetch(
    `api/brands/?filters[name][$eq]=${category}`
  );

  const {
    data: productsDB,
    metadata,
    loading,
  } = useFetch(url.length ? url + `&pagination[page]=1` : "");

  useEffect(() => {
    if (brandDB && categoryDB) handleFilters();
  }, [
    isNew,
    isFeatured,
    isPromotion,
    isPreorder,
    isInStock,
    category,
    price,
    brandDB,
    categoryDB,
    querySearch,
    subcategory,
  ]);

  useEffect(() => {
    handleIncomingProducts();
  }, [productsDB]);

  useEffect(() => {
    handleSortBy(products);
  }, [sortBy]);

  useEffect(() => {
    setIsNew(queryIsNew === "true" ? true : false);
    setIsFeatured(queryIsFeatured === "true" ? true : false);
    setIsPromotion(queryIsPromotion === "true" ? true : false);
    setIsPreorder(queryIsPreorder === "true" ? true : false);
    setSortBy(querySortBy !== null ? querySortBy : null);
    setIsInStock(queryIsInStock === "true" ? true : false);
    setPrice([
      queryMinPrice !== null ? queryMinPrice : 0,
      queryMaxPrice !== null ? queryMaxPrice : 3000,
    ]);
    setSliderValue([
      queryMinPrice !== null ? queryMinPrice : 0,
      queryMaxPrice !== null ? queryMaxPrice : 3000,
    ]);
    setPage(queryPage !== null ? Number(queryPage) : 1);
  }, [location.pathname]);

  // set products of page 1
  // if page > 1, get products of page 2, 3, 4, ...
  // then handle loading
  // then handle scroll (to last scroll position if user came from product page else to top)
  const handleIncomingProducts = async () => {
    if (productsDB !== null) {
      setProducts(productsDB);
      let tmpProducts = productsDB;
      if (page >= 2) {
        tmpProducts = productsDB.slice();
        for (let i = 2; i <= page; i++) {
          const newProducts = await getDataByPage(i);
          tmpProducts = tmpProducts.concat(newProducts);
        }
      }
      handleSortBy(tmpProducts);
      setLoading2(false);
      const previousUrl = sessionStorage.getItem("previousLocation");

      if (previousUrl && previousUrl.includes("/product/")) {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        setTimeout(() => window.scrollTo(0, scrollPosition), 100);
      } else {
        setTimeout(
          () =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            }),
          100
        );
      }
    }
  };

  const handleSortBy = (prod) => {
    if (prod) {
      let tmpProducts = prod.slice();
      if (sortBy === "1") {
        tmpProducts = tmpProducts.sort(
          (a, b) =>
            b.attributes.options[0].price - a.attributes.options[0].price
        );
      } else if (sortBy === "2") {
        tmpProducts = tmpProducts.sort(
          (a, b) =>
            a.attributes.options[0].price - b.attributes.options[0].price
        );
      }
      setProducts(tmpProducts);
    }
  };
  
  const handleFilters = () => {
    let filter = `api/products/?sort[0]=publishedAt:desc&populate[image]=*&populate[brand]=*&populate[categories]=*&populate[options]=*&pagination[pageSize]=25&filters[region][$eq]=${region}`;

    if (querySearch) filter += `&filters[title][$containsi]=${querySearch}`;
    if (category && categoryDB?.length)
      filter += `&filters[categories][title][$contains]=${category}`;
    if (category && brandDB?.length)
      filter += `&filters[brand][name][$eq]=${category}`;
    if (subcategory)
      filter += `&filters[subcategories][title][$eq]=${subcategory}`;
    if (isNew) filter += "&filters[type][$eq]=new";
    if (isPromotion) filter += "&filters[type][$eq]=promotion";
    if (isFeatured) filter += "&filters[type][$eq]=featured";
    if (isPreorder) filter += "&filters[type][$eq]=preorder";
    if (isInStock) filter += "&filters[options][quantity][$gt]=0";
    if (price[0] !== 0) filter += `&filters[options][price][$gte]=${price[0]}`;
    if (price[1] !== 3000)
      filter += `&filters[options][price][$lte]=${price[1]}`;
    setUrl(filter);
    setPage(page);
  };

  // is called when user click on load more button
  const handleAddMore = async (page) => {
    const newProducts = await getDataByPage(page);
    let tmpProducts = products.slice();
    tmpProducts = tmpProducts.concat(newProducts);
    handleSortBy(tmpProducts);
    setPage(page);
    handleQueryChange("page", page);
  };

  // only gets products of a specific function (used in handleAddMore & handleIncomingProducts)
  const getDataByPage = async (page) => {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + url + `&pagination[page]=${page}`
    );
    return res.data.data;
  };

  // sets query params
  const handleQueryChange = (param, value) => {
    searchParams.set(param, value);
    navigate(location.pathname + "?" + searchParams.toString(), {
      replace: true,
    });
  };

  return (
    <>
      {!loading2 && products !== null && categoryDB !== null && subcategory !== null ? (
        <>
          <Helmet>
            <title>
              Skyshop -{" "}
              {!category
                ? querySearch
                  ? querySearch
                  : "SkyShop: Your Premier Destination for Drone FPV Enthusiasts"
                : subcategory
                ? `${subcategoryDB[0]?.attributes.metadata_title ? subcategoryDB[0]?.attributes.metadata_title : subcategory}`
                : `${categoryDB[0]?.attributes.metadata_title ? categoryDB[0]?.attributes.metadata_title : category}`}
            </title>
            <meta
              name="description"
              content={
                !category
                  ? querySearch
                    ? querySearch
                    : "SkyShop is here to help you make the most of your FPV Drone flying experience. Shop now and experience the thrill of FPV like never before!"
                  : subcategory
                  ? `${subcategoryDB[0]?.attributes.metadata_description ? subcategoryDB[0]?.attributes.metadata_description : subcategory}`
                  : `${categoryDB[0]?.attributes.metadata_description ? categoryDB[0]?.attributes.metadata_description : category}`
              }
            />
          </Helmet>

          <div className="w-full md:mb-[200px] mb-20">
            <div className="flex flex-col justify-center items-start p-4 md:p-6 lg:p-8 2xl:pl-14">
              <div className="mb-4">
                <Breadcrumbs
                  separator="›"
                  aria-label="breadcrumb"
                  className="!text-white !text-sm !breadcrumbs !scrollbar-thumb-rounded-full !scrollbar-thumb-base-100 !pb-4 !scrollbar-thumb-sm"
                >
                  <Link to="/">Home</Link>
                  <Link to="/products">Products</Link>
                  {category ? (
                    <Link to={`/products/${encodeURIComponent(category)}`}>
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </Link>
                  ) : querySearch ? (
                    <Link to={`/products?search=${querySearch}`}>
                      {querySearch}
                    </Link>
                  ) : (
                    <Link to={`/products`}>All Products</Link>
                  )}
                  {subcategory && (
                    <Link
                      to={`/products/${encodeURIComponent(
                        category
                      )}/${encodeURIComponent(subcategory)}`}
                    >
                      {subcategory}
                    </Link>
                  )}
                </Breadcrumbs>
              </div>
              <h2 className="text-xl xl:text-3xl font-bold text-white uppercase mb-4">
                {!category
                  ? querySearch
                    ? `Search in ${querySearch}`
                    : "All Products"
                  : subcategory
                  ? subcategory
                  : category}
              </h2>
              {category && categoryDB && brandDB ? (
                categoryDB?.length ? (
                  <p className="max-w-[700px] mb-4">
                    {categoryDB[0]?.attributes.description}
                  </p>
                ) : brandDB.length ? (
                  <p className="max-w-[700px] mb-4">
                    {brandDB[0]?.attributes.description}
                  </p>
                ) : (
                  <></>
                )
              ) : querySearch ? (
                <></>
              ) : (
                <></>
              )}
              <div className="flex flex-row justify-between md:justify-start items-center w-full border-t-2 border-t-base-100 pt-6 space-x-8 md:space-x-0 mb-4">
                <BsSliders
                  className="w-6 h-6 font-thin text-[#A6ADBB] md:hidden"
                  onClick={() => setIsSidebar(!isSidebar)}
                />
                <div className="pl-0">
                  <Select
                    color="cyan"
                    label="Sort By"
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                    className="!ml-0"
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e);
                      handleQueryChange("sortBy", e);
                    }}
                  >
                    <Option value={"0"}>Default</Option>
                    <Option value={"1"}>Highest Price First</Option>
                    <Option value={"2"}>Lowest Price First</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full relative mb-8">
              <Sidebar
                open={isSidebar}
                handleSidebar={() => setIsSidebar(!isSidebar)}
                setIsNew={setIsNew}
                isNew={isNew}
                setIsFeatured={setIsFeatured}
                isFeatured={isFeatured}
                setIsPreorder={setIsPreorder}
                isPreorder={isPreorder}
                setIsPromotion={setIsPromotion}
                isPromotion={isPromotion}
                isInStock={isInStock}
                setIsInStock={setIsInStock}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                setPrice={setPrice}
                productQuantity={metadata ? metadata.pagination.total : 0}
                handleQueryChange={handleQueryChange}
              />
              <div className="w-full mx-auto flex items-center justify-center">
                <ListProduct products={products} loading={loading} />
              </div>
            </div>
            {metadata && (
              <>
                {page < metadata.pagination.pageCount && (
                  <div className="w-full p-4 flex justify-center items-center px-12 space-x-4">
                    <div className="h-[2px] w-full bg-primary"></div>
                    <p
                      onClick={() => {
                        handleAddMore(page + 1);
                      }}
                      className="font-semibold tracking-widest uppercase hover:text-primary text-center cursor-pointer text-xl whitespace-nowrap"
                    >
                      Load more
                    </p>
                    <div className="h-[2px] w-full bg-primary"></div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
}

export default Products;
