import { useState, createContext, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import RegionModal from "../components/RegionModal";

const RegionCheckerContext = createContext(null);

export const RegionCheckerProvider = ({ children }) => {
  const location = useLocation();

  const [region, setRegion] = useState(localStorage.getItem("region"));
  const [country, setCountry] = useState(localStorage.getItem("country"));
  const [currency, setCurrency] = useState("$");
  const [defaultRegion, setDefaultRegion] = useState(
    localStorage.getItem("defaultRegion")
  );
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!defaultRegion) {
      localStorage.setItem("defaultRegion", true);
      localStorage.setItem("region", "europe");
      localStorage.setItem("country", "FR");
    }

    if (!region || !country || defaultRegion == "true") {
      setOpenModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (region === "europe") setCurrency("€");
    else setCurrency("$");
  }, [region]);

  return (
    <RegionCheckerContext.Provider
      value={{
        region,
        setRegion,
        country,
        setCountry,
        currency,
        defaultRegion,
        setDefaultRegion,
        openModal,
        setOpenModal,
      }}
    >
      {openModal && <RegionModal setOpen={setOpenModal} />}
      {children}
    </RegionCheckerContext.Provider>
  );
};

export const useRegionChecker = () => {
  return useContext(RegionCheckerContext);
};
