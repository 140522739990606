import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Hero from "../components/Hero";
import Tabs from "../components/Tabs";
import ParallaxCard from "../components/ParallaxCard";
import Team from "../components/Team";

function Home() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const confirmed = queryParams.get("confirmed");
  const reset = queryParams.get("reset");

  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (confirmed) {
      fetchJWT()
      handleToast();
    }
  }, [confirmed]);

  useEffect(() => {
    if (reset) handleToast();
  }, [reset]);

  const handleToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  const CloseToast = () => {
    setShowToast(false);
  };

  const fetchJWT = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "api/auth/local",
        {
          identifier: localStorage.getItem("email"),
          password: localStorage.getItem("password"),
        }
      );

      if (res) {
        localStorage.setItem("jwt", res.data.jwt);
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <Helmet>
        <title>SkyShop - Your One-Stop Shop for Drone FPV Items</title>
        <meta name="description" content="FPV flying serves up an incredible real-time experience, and the perspective has several big advantages. our Premier Destination for Drone Enthusiasts" />
      </Helmet>
      <div
        id="toast-notification"
        className={
          showToast
            ? "w-full max-w-[300px] p-4 text-gray-900 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-300 fixed bottom-4 right-4 z-[10000]"
            : "hidden"
        }
        role="alert"
      >
        <div className="flex items-center mb-3">
          <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">
            {reset ? "Password Changed" : "Email Verified"}
          </span>
          <button
            type="button"
            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            data-dismiss-target="#toast-notification"
            aria-label="Close"
            onClick={CloseToast}
          >
            <span className="sr-only">Close</span>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <Hero />
      <Tabs />
      <ParallaxCard />
      <Team />
    </div>
  );
}

export default Home;
