import { useEffect, useRef } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

function ScrollToTop({ children }) {
  const location = useLocation();
  const navType = useNavigationType();
  const prevLocation = useRef();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const isNavigatingFromProductDetail =
      prevLocation.current &&
      (prevLocation.current.pathname.includes("/product") ||
        prevLocation.current.pathname.includes("/products")) &&
      location.pathname.includes("/products");

    if (
      !isNavigatingFromProductDetail
    ) {
      setTimeout(() => scrollToTop(), 100);
    }

    if (prevLocation.current) {
      sessionStorage.setItem('previousLocation', prevLocation.current.pathname);
    }

    prevLocation.current = location;
  }, [location, navType]);

  return children;
}

export default ScrollToTop;
