import { Helmet } from "react-helmet";

function VerifyEmail() {
    return (
        <>
            <Helmet>
                <title>Verify Email</title>
            </Helmet>
            <div className="w-full mx-auto h-screen flex items-center justify-center p-8">
                <div className="rounded-xl border-2 border-primary max-w-xl p-8 flex flex-col justify-between">
                    <h1 className="text-secondary-content text-2xl uppercase font-bold mb-4">
                        Verify Your Email
                    </h1>
                    <div className="w-full h-[1px] bg-gray-400 mb-4"></div>

                    <p className="mt-[30px]">Thank you for signing up!<br /><br /> To complete the registration process, please click on the link we've sent to your inbox to confirm your email address.</p>
                </div>
            </div>
        </>
    );
}

export default VerifyEmail;
