import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useCountries } from "use-react-countries";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useJwt } from "react-jwt";
import {
  Input,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import axios from "axios";
import Loading from "../components/Loading";
import { useRegionChecker } from "../hooks/regionChecker";
import useFetch from "../hooks/useFetch";

function AddressPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const products = useSelector((state) => state.cart.products);
  const promoCode = useSelector((state) => state.promo.promoCode);

  const { country: countryChecker } = useRegionChecker();

  const { countries } = useCountries();
  const { name, flags, countryCallingCode } = countries[121];

  const LebanonData = [{ name, flags, countryCallingCode }];

  const { decodedToken } = useJwt(localStorage.getItem("jwt"));

  const { data: user } = useFetch(`api/users/me`, true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [callingCode, setCallingCode] = useState("+961");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("Lebanon");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [floor, setFloor] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");

  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setFirstName(user?.first_name);
      setLastName(user?.last_name);
      setEmail(user?.email);
    }
  }, [user]);

  const handleCheckout = async () => {
    setError(false);
    const consignee = {
      firstName: firstName,
      lastName: lastName,
      mobile: callingCode + " " + phoneNumber,
      email: email,
      specialInstructions: additionalInformation,
      address: {
        country: country,
        city: city,
        street: street,
        building: building,
        zipCode: postalCode,
        floor: floor,
      },
    };

    if (localStorage.getItem("jwt") && countryChecker === "LB") {
      const productList = products.map((prd) => {
        return {
          id: prd.id,
          quantity: prd.quantity,
          optionId: prd.optionId,
        };
      });
      const payload = {
        consignee: consignee,
        items: productList,
        promoCode: promoCode !== null ? promoCode[0].attributes?.code : null,
        userId: decodedToken?.id,
        country: countryChecker,
      };
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };

      try {
        setLoading(true)

        const res = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "api/netGlobal",
          payload,
          config
        );
        
        navigate("/orders?success=true");
      } catch (err) {
        console.log(err);
        setLoading(false)
        navigate("/checkout", { state: { error: err.response.data} })
      }
      setError(false);
    } else {
      navigate("/login", { state: { from: location } });
    }
  };

  return (
    <>
      <Helmet>
        <title>Address</title>
      </Helmet>
      {!loading ? (
        <div className="w-full mx-auto flex justify-center items-center">
          <div className="max-w-[1400px] w-full p-4 md:p-8">
            <h1 className="text-xl xl:text-3xl font-bold text-white uppercase">
              Your Address
            </h1>
            {/* <p className="mt-8">
              Please fill in all the required fields ( fields marqued by * are
              optional )
            </p> */}
            <div className="w-full h-[1px] bg-gray-700 mt-8 mb-10 md:mb-14"></div>
            <div className="grid grid-cols-2 gap-4 md:gap-8 lg:gap-10 w-full mb-10">
              <div className="col-span-2 flex flex-col space-y-2">
                <h4 className="text-lg text-secondary-content font-semibold">
                  Contact Information
                </h4>
                <div className="w-full h-[1px] bg-primary"></div>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <Input
                  id="FirstNameLB"
                  variant="outlined"
                  label="First Name *"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content "
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <Input
                  id="LastNameLB"
                  variant="outlined"
                  label="Last Name *"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content "
                />
              </div>
              <div className="relative flex w-full col-span-2 md:col-span-1">
                <Menu placement="bottom-start">
                  <MenuHandler>
                    <Button
                      ripple={false}
                      variant="text"
                      color="blue-gray"
                      className="flex items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-300 bg-blue-gray-500/10 pl-3 h-[40px]"
                    >
                      <img
                        src={flags.svg}
                        alt={name}
                        className="h-4 w-4 rounded-full object-cover"
                      />
                      {countryCallingCode}
                    </Button>
                  </MenuHandler>
                  <MenuList className="max-h-[20rem] max-w-[18rem] overflow-y-scroll scrollbar-custom">
                    {countries.map(
                      ({ name, flags, countryCallingCode }, index) => {
                        return (
                          <MenuItem
                            key={name}
                            value={name}
                            className="flex items-center gap-2"
                            onClick={() =>
                              setCallingCode(
                                countries[index].countryCallingCode
                              )
                            }
                          >
                            <img
                              src={flags.svg}
                              alt={name}
                              className="h-5 w-5 rounded-full object-cover"
                            />
                            {name}{" "}
                            <span className="ml-auto">
                              {countryCallingCode}
                            </span>
                          </MenuItem>
                        );
                      }
                    )}
                  </MenuList>
                </Menu>
                <Input
                  id="registerPhone"
                  // error={error}
                  type="tel"
                  placeholder="Mobile Number *"
                  color="cyan"
                  className="rounded-l-none !h-[40px] focus:!border-primary text-secondary-content"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  containerProps={{
                    className: "min-w-0",
                  }}
                  onChange={(e) => setphoneNumber(e.target.value)}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <Input
                  variant="outlined"
                  label="Email *"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content"
                />
              </div>
              <div className="col-span-2 flex flex-col space-y-2 pt-8">
                <h4 className="text-lg text-secondary-content font-semibold">
                  Address
                </h4>
                <div className="w-full h-[1px] bg-primary"></div>
              </div>
              <div className="col-span-2 md:col-span-1">

                <Select
                  size="lg"
                  label="Country *"
                  color="cyan"
                  selected={(element) =>
                    element &&
                    React.cloneElement(element, {
                      className:
                        "flex items-center px-0 gap-2 pointer-events-none",
                    })
                  }
                  value={LebanonData[0].name}
                >
                  {LebanonData.map(({ name, flags }) => (
                    <Option
                      key={name}
                      value={name}
                      className="flex items-center gap-2"
                      onClick={() => setCountry(name)}
                    >
                      <img
                        src={flags.svg}
                        alt={name}
                        className="h-5 w-5 rounded-full object-cover"
                      />
                      {name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <Input
                  variant="outlined"
                  label="City *"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content "
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <Input
                  variant="outlined"
                  label="Street *"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content "
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <Input
                  variant="outlined"
                  label="Building *"
                  value={building}
                  onChange={(e) => setBuilding(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content "
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <Input
                  variant="outlined"
                  label="Postal Code"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content "
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <Input
                  variant="outlined"
                  label="Floor"
                  value={floor}
                  onChange={(e) => setFloor(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content "
                />
              </div>
              <div className="col-span-2 sm:col-span-1 h-full">
                <Textarea
                  variant="outlined"
                  label="Additional Information"
                  value={additionalInformation}
                  onChange={(e) => setAdditionalInformation(e.target.value)}
                  color="cyan"
                  className="w-full text-secondary-content h-40"
                />
              </div>
            </div>

            <button
              className="btn btn-primary sm:max-w-[300px] mt-8"
              onClick={handleCheckout}
              disabled={
                firstName === "" ||
                lastName === "" ||
                email === "" ||
                phoneNumber === "" ||
                country === "" ||
                city === "" ||
                street === "" ||
                building === ""
              }
            >
              Submit Info
            </button>
            {error == false && (
              <p className="text-red-500 font-light mt-2">
                An error occured, please try again
              </p>
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default AddressPage;
