import { Link } from "react-router-dom";
import Brand from "../components/Brand";
import Loading from "../components/Loading";
import useFetch from "../hooks/useFetch";
import { Helmet } from "react-helmet";

function Brands() {
  const { data: brands, loading } = useFetch(`api/brands?populate[image]=*`);

  return (
    <>
      <Helmet>
        <title>Top Brands, Top Performance: Explore Leading LiPo Batteries</title>
        <meta name="description" content="Discover our extensive selection of LiPo batteries, featuring trusted brands such as BetaFPV, DJI, Axis Flying, Ovonic, GEPRC, and more, get the power and reliability " />
      </Helmet>
      {!loading && brands ? (
        <div className="w-full mx-auto flex justify-center items-center">
          
          <div className="max-w-[1400px] w-full p-4 md:p-8 flex flex-col items-center ">
            <h1 className="text-xl xl:text-3xl font-bold text-white uppercase mb-10">
              our brands
            </h1>
            {/* <div className="w-full h-[1px] bg-gray-700 mt-8 mb-10 md:mb-14"></div> */}
            <div className="w-full flex flex-row flex-wrap justify-center align-start gap-8 md:gap-12">
              {brands?.map((brand, index) => {
                return (
                  <Link
                    to={`/products/${encodeURIComponent(brand.attributes.name)}`}
                    key={index}
                  >
                    <Brand
                      imgsrc={
                        brand.attributes.image.data
                          ? brand.attributes.image.data.attributes.url
                          : null
                      }
                      name={brand.attributes.name}
                    />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Brands;
