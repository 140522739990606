function BlogCard({ blog }) {
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based in JavaScript
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="w-full flex flex-col space-y-4 cursor-pointer group">
      <div className="w-full rounded-xl aspect-video relative overflow-hidden">
        <div className="absolute w-full h-full group-hover:scale-105 duration-150 ease-in">
          <img
            className="w-full h-full object-cover object-center brightness-[0.8] group-hover:brightness-[1] duration-300 ease-in"
            src={blog.thumbnail.data.attributes.url}
            alt="blog img"
          />
        </div>
      </div>
      <h3 className="text-2xl text-white font-bold">{blog.title}</h3>
      <p className="line-clamp-3">{blog.small_description}</p>
      <p className="text-lg font-semibold">
        {blog.author} - {formatDate(blog.publishedAt)}
      </p>
    </div>
  );
}

export default BlogCard;
